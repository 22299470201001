import { ITheme } from '@/shared/styles/MuiTheme';
import { makeStyles } from '@material-ui/core';

export interface IFlfStyleProps {
    isTopNavVisible: boolean
}

export const useNewsStyles = makeStyles((theme) => ({
    headerContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    newsHeadline: {
        flex: 1
    },
    adRoot: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            margin: '16px 0 8px',
        }
    },
    pre: {
        whiteSpace: 'pre'
    },
    image: {
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '600px'
    },
    headLine: {
        marginTop: 10,
        border: `1px solid #F5F5F5`
    },
    docWrapper: {
        paddingTop: 20
    },
    docNameWrapper: {
        cursor: 'pointer'
    },
    docName: {
        marginLeft: 12,
        fontWeight: 700,
        fontSize: 12,
        textDecoration: 'underline'
    },
    downloadButton: {
        '& img': {
            width: theme.spacing(5),
            height: theme.spacing(5)
        }
    },
    newsLink: {
        textAlign: 'initial'
    }
}))

export const useFlfNewsStyles = makeStyles((theme: ITheme) => ({
    carouselTextColor: {
        '& h2, & h3, & p': {
            color: '#FFFFFF !important'
        },
    },
    heroContent: {
        position: 'absolute',
        bottom: 0,
        paddingBottom: 54,
        zIndex: 3,
        '& h2': {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            [theme.breakpoints.down('sm')]: {
                '-webkit-line-clamp': 3,
            },
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& p': {
            display: '-webkit-box',
            '-webkit-line-clamp': 4,
            [theme.breakpoints.down('sm')]: {
                '-webkit-line-clamp': 3,
            },
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    contentContainer: {
        position: 'relative',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    controls: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    carouselImageWrapper: {
        width: '100vw',
        height: (props: IFlfStyleProps) => `calc(100vh - ${props.isTopNavVisible ? 168: 104}px)`,
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '0%',
            right: '0%',
            top: '0%',
            bottom: '0%',
            zIndex: 2,
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 77.08%)',
        },
    },
    carouselImage: {
        minWidth: '100%',
        objectFit: 'cover',
        minHeight: '100%',
        objectPosition: 'top',
    },
    carouselWrapper: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        '& .carousel .control-dots': {
            bottom: 32
        },
        '& .carousel-root': {
            position: 'relative',
            zIndex: 3,
        }
    },
    backdrop: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '0%',
            right: '0%',
            top: '0%',
            bottom: '0%',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 41.68%, rgba(0, 0, 0, 0.7) 100%)',
        },
    }
}))