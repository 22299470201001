import { Headline2 } from '@/shared/components/Typography'
import Box from '@material-ui/core/Box'
import { useIntl } from 'react-intl'
import { sectionMessages } from '../messages'
import { useFlfNewsPlaceholderStyles } from './styles'

export default function EmptyFlfNews() {
    const intl = useIntl()
    const classes = useFlfNewsPlaceholderStyles()
    return (
        <Box className={classes.root} display='flex' alignItems={'end'}>
            <Headline2>
                {intl.formatMessage(sectionMessages['news.empty_news'])}
            </Headline2>
        </Box>
    )
}