import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { ITheme } from '@/shared/styles/MuiTheme'
import Icon from '../Icon/Icon'
import { useTheme } from '@material-ui/core/styles'

interface IStyleProps {
    circle?: boolean
}

const tagStyles = makeStyles((theme: ITheme) => ({
    tag: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: (props: IStyleProps) => {
            if(props.circle) {
                return '50%'
            }
            return '25px';
        },
        backgroundColor: theme.props.sectionMode.card,
        color: theme.props.sectionMode.text_background,
        '& svg, & i': {
            color: theme.props.sectionMode.text_background,
        },
    },
    'large': {
        height: '48px',
        width: '48px',
        '& img, & svg, & i': {
            height: '24px',
            width: '24px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '32px',
            width: '32px',
            '& img, & svg, & i': {
                height: '16px',
                width: '16px',
            },
        },
    },
    'medium': {
        height: '32px',
        width: '32px',
        '& img, & svg, & i': {
            height: '16px',
            width: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '24px',
            width: '24px',
            '& img, & svg, & i': {
                height: '12px',
                width: '12px',
            },
        },
    },
    'small': {
        height: '24px',
        width: '24px',
        '& img, & svg, & i': {
            height: '12px',
            width: '12px',
        },
    },
}))

interface IProps {
    icon?: React.ReactNode;
    imgSrc?: string;
    size?: 'small' | 'medium' | 'large';
    circle?: boolean
}

export default function Tag({ icon, imgSrc, size = 'large', circle }: IProps): JSX.Element {
    const classes = tagStyles({ circle })
    const theme: ITheme = useTheme()

    return (
        <div className={`${classes.tag} ${classes[size]}`}>
            {imgSrc ? <Icon src={imgSrc} hexColor={theme.props.sectionMode.text_background} /> : icon}
        </div>
    )
}